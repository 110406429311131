.userIcon{
    display: flex;
    width: 50px;
    height: 50px;
    background-color: #1B1D1B;
    border-radius: 12px;
    overflow: hidden;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    user-select: none;
    transform: rotate(45deg);
    margin-bottom: 10px;
}

.userIcon p{
    transform: rotate(-45deg);
}