.grid{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
}

.loaderWrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
}