#createPage > h1{
    font-weight: normal;
    font-size: 40px;
    text-align: left;
    color: #262626;
}

#createPage > p{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #168eea;
}

.createInspection{
    max-width: 800px;
    margin: auto;
    background-color: #F5F6F7;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.createInspectionButton{
    display: flex;
    justify-content: flex-end;
}

.createInspectionButton button{
    height: 48px;
    width: 117px;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}

.createInspectionButton button:first-of-type{
    background: #282828;
}

.createInspectionButton button:last-of-type{
    background: #e00000;
}

.createInspection input, .createInspection select{
    border: none;
}

.createInspectionHeader{
    display: flex;
    padding: 25px;
    width: 100%;
    background-color: #FFFFFF;
}

.createInspectionHeader h3{
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #262626;
}

.createInspectionHeader p{
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #59626a;
}

.createInspectionHeader svg{
    margin-right: 10px;
}

.createInspectionBody{
    margin: 15px;
    display: flex;
    flex-direction: column;
}
.inp-select{
    max-width: 216px;
}

.error {
    font-size: 10px;
    color: #DD0E0E;
    margin-top: 10px;
    font-weight: 500;
}
