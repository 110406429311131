.fault-codes-table{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fault-codes-table.c-bordered{
    border: 1px solid #4b5156;
}
.fault-codes-table .material-icons{cursor: pointer;}
.fault-codes-table .createFormGroup{
    margin: 15px 0;
    width: 49%;
}