.confirmBookingModal {
  margin-top: 13%;
}

.confirmBookingModal .modal-content {
  background-color: #2a2e43;
  border: none;
  border-radius: 12px;
}

.confirmBookingModal .modal-header {
  border: none;
}

.confirmBookingModal h5.modal-title {
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  color: #fff;
}

.confirmBookingModal .modal-header button span {
  color: #ffffff;
  text-shadow: none;
}

.confirmBookingModal .modal-header button {
  outline: none;
}

.confirmBookingModal .modal-body {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #fff;
  opacity: 0.68;
}

.confirmBookingModal .modal-body p {
  margin: 0;
}

.confirmBookingModal .modal-footer {
  border: none;
  justify-content: center;
}

.confirmBookingModal .modalButton {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fd0000;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
}

.confirmBookingModal .modalButton:hover {
  opacity: 1;
}

.confirmBookingModal .modalButton span {
  display: flex;
  align-items: center;
}

.confirmBookingModal .modalButton.accept {
  background: #00fa87;
}

.confirmBookingModal.router .decline {
  display: none;
}
.showBtn {
  min-width: 106px;
  padding: 14px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: 0.3s;
}
.showBtn:hover {
  opacity: 0.7;
}

.showAcceptBtn {
  background: #fff;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #000000;
  user-select: none;
  cursor: pointer;
}

.showDeclineBtn {
  opacity: 0.9;
  color: #000000;
  user-select: none;
  cursor: pointer;
  background: #e00000;
  color: #fff;
}
