.tutorial{
    max-width: 510px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px auto;
    user-select: none;
}

.tutorial-line{
    position: relative;
    border: 1px solid #9599A6;
    z-index: -1;
    width: 80%;
    bottom: -21px;
}

.tutorial-items{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tutorial-item{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.circle {
    display: flex;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    background-color: #9599A6;
    color: #FFFFFF;
    z-index: 0;
    margin-left: -2px;
    font-size: 12px;
    cursor: pointer;
}

.circle.active{
    background-color: #E00000;
}

.circle:last-of-type {
    margin-right: 0;
}

.circle:last-of-type:after {
    display: none;
}

.tutorial-caption {
    margin-top: 8px;
    min-width: 80px;
    margin-right: 60px;
    font-size: 11px;
    color: #343740;
    line-height: 1.1;
    text-align: center;
}

.tutorial-caption:last-of-type {
    margin-right: 0;
}
