.redLine{
    width: 100%;
    border: 2px solid #e00000;
    border-radius: 2px;
    margin-bottom: 10px;
}

.createFormGroup{
    margin: 10px;
    display: flex;
    flex-direction: column;
}

#customerDetails .createInspectionBody h4{
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #262626;
}

#customerDetails .createFormGroup input{
    min-width: 300px;
    height: 50px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}

#customerDetails .createFormGroup label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
}

#customerDetails .flag-container{
    padding: 0;
}

#customerDetails .selected-flag{
    border-radius: 6px;
    outline: none;
    background: #FFFFFF;
}

#customerDetails .selected-flag:hover{
    background: #dfe2e6;
}

#customerDetails .iti-arrow{
    right: 8px;
}

.checkForm label{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
    margin: 10px;
}

.checkForm input{
    margin-right: 5px;
    width: 18px;
    height: 18px;
}

.selected-flag{
    background-color:rgba(0,0,0,.05)
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel],
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text] {
    padding-left: 105px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
    width: 95px;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: rgba(213, 215, 219, 0.78)!important;
    display: table;
}