#vehicleDetails input,
#vehicleDetails select {
  height: 50px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
}
#vehicleDetails input::-webkit-calendar-picker-indicator {
  opacity: 100;
}
#vehicleDetails .datalist-input {
  position: relative;
}
#vehicleDetails .datalist-input::before {
  content: "";
  border-top: 10px solid;
  border-bottom: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}

#vehicleDetails label {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #262626;
}

#vehicleDetails .fileUploader {
  width: auto;
}

.downloadForm {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeef3;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.downloadForm .upload-vin button {
  width: 109px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  border-radius: 8px;
  background: #e00000;
  box-shadow: 0 12px 16px rgba(69, 91, 99, 0.1);
}

.downloadForm .createFormGroup:first-child {
  box-sizing: content-box;
  width: 55%;
  min-width: 250px;
}

.downloadForm .createFormGroup:last-child {
  box-sizing: border-box;
  width: 38%;
  min-width: 250px;
}

.fileContainer .chooseFileButton {
  padding: 0;
}

.fileContainer .downloadPhotoButton {
  box-sizing: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  background-color: #e00000;
  border-radius: 50%;
  margin: 0 0 0 10px;
  user-select: none;
  cursor: pointer;
}

.downloadPhotoButton:hover {
  background-color: #c90000;
}

.gridForm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
  min-height: 200px;
}

.formShort {
  box-sizing: border-box;
  width: 20%;
  min-width: 120px;
  padding: 0 5px;
}

.formLong {
  box-sizing: border-box;
  width: 40%;
  min-width: 250px;
  padding: 0 5px;
}

.drug-and-drop-input {
  height: 48px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
  display: flex;
  width: 100%;
  color: #858585;
  border: 1px dashed #858585;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  user-select: none;
}

.drug-and-drop-input:hover {
  cursor: copy;
}

.loader-wrapper {
  flex-direction: column !important;
}
