.navBar {
    display: flex;
    margin: 20px 10px 0 0;
    flex-direction: column;
    width: 200px;
    min-width: 200px;
}

.userWrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.userWrapper p {
    margin: 0;
    padding: 0;
}

.userWrapper > p:first-of-type{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
}

.userWrapper > p:last-of-type{
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #8a8f9c;
}

.inspections {
    margin: 20px 0 8px 0;
}

.inspections > div {
    user-select: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.inspections > div svg{
    margin-right: 7px;
}

.inspections div.active {
    color: #E00000;
}

.inspections div.disabled {
    color: #8D929E;
}

.inspections div.disabled:hover {
    color: #428bca;
}

button.bookInspectionButton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    background: #e00000;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
}

button.bookInspectionButton svg{
    margin-right: 7px;
}

.inspections .title {
    font-weight: 600;
    text-align: left;
    color: #8a8f9c;
    margin: 0 0 10px 0;
}

.inspections > .filter {
    margin-left: 10px;
}


.settingsButton{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #8a8f9c;
    margin-bottom: 15px;
}

.settingsButton.active{
    color: #E00000;
}

.settingsButton.active svg path{
    fill: #E00000;
}

.settingsButton:hover{
    color: #E00000;
    cursor: pointer;
}

.settingsButton:hover svg path{
    fill: #E00000;
}

.settingsButton svg{
    margin-right: 7px;
}

.support{
    margin-bottom: 20px;
}

.support h6{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #8a8f9c;
}

.support a{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #8a8f9c;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.support a:hover{
    color: #E00000;
}

.support a svg{
    margin: 0 10px;
}