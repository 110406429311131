.locations-table{
    display: flex;
    justify-content: center;
}

.locations-table .locations-table-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.locations-table .locations-table-wrapper > div:first-of-type{
    height: 200px;
    margin-bottom: 15px;
}

.map-hide{
    display: none;
    margin: 0 !important;
    height:0 !important;
    opacity: 0;
    overflow: hidden;
}

.nickname-wrapper{
    /*padding-top: 48px*/
}