.date-selector{
    margin-right: 10px;
    display: flex;
    align-items: flex-start;
}

.date-selector input{
    height: 38px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
    border: none;
    min-height: 0;
}

.date-selector button.dropdown-toggle{
    background-color: #E00000;
}

.date-selector button.dropdown-item.active{
    background-color: #6c757d;
    color: #FFFFFF;
}
.date-selector button.dropdown-item:active{
}

#booking .date-selector .react-datepicker-wrapper{
    width: 100%;
}