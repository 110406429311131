.users-table .new-user-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
}

.users-table .new-user-wrapper .new-user-buttons{
    margin: 0 0 25px 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.add-user-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 25px;
    margin-bottom: 25px;
}

.add-user-form > div{
    display: flex;
    justify-content: space-between;
}

.add-user-form div.add-user-custom-size{
    width: 32.5%;
}

.users-table .new-user-wrapper .error-message{
    width: 100%;
    text-align: center;
    color: #E00000;
    margin: 0;
    font-size: 13px;
}