.bodyWrapper{
    display: flex;
    flex-direction: column;
    margin: 0 auto 100px auto;
    max-width: 1100px;
    padding: 0 10px;
}

.header{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerLogo{
    width: 200px;
    display: flex;
    height: 120px;
    cursor: pointer;
}

.headerLogo img{
    width: 210px;
    margin: auto;
}