footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 99;
    background: #f5f6f7;
    height: 110px;
}

footer div{
    margin: 25px auto;
    padding-top: 15px;
    width: 75%;
    background: transparent;
    border-top: 5px solid #e2e2e2;
}

footer div p{
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #9E9E9E;
    margin: 0;
}

footer div p span{
    color: #E00000;
}