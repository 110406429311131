body{
    background-color: #F5F6F7;
}

.landing-container{
    display: flex;
    flex-direction: column;
    background: transparent;
    padding-bottom: 25px;
    /*border-bottom: 1px solid #eee;*/
    margin-bottom: 20px;
    margin-top: 50px;
}

.landing-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.landing-wrapper p:first-of-type{
    color: #59626A;
    width: 80%;
    height: 46px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.landing-wrapper p:last-of-type{
    width: 90%;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #168eea;
    cursor: pointer;
}

.landing-wrapper h3{
    margin: 0 0 15px 0;
    font-weight: 600;
    font-size: 25px;
    text-align: left;
    color: #262626;
}

.landing-container img{
    text-align: center;
}

.signInForm button{
    height: 50px;
    background: #e00000;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.02));
}

.signInForm input{
    height: 50px;
    border-radius: 6px;
    background: #fff;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}

.signInForm label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
}

.signInForm p{
    color: #59626A;
    width: 100%;
    height: 46px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    text-align: center;
}