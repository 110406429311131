#inspectionDetails .createInspectionBody label{
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #262626;
    white-space: nowrap;
    margin-right: 10px;
}

#inspectionDetails .inspectionType{
    width: 150px;
    margin: 0 10px 0 0;
}

#inspectionDetails .inspectionType:hover{
    cursor: pointer;
    background-color: #023758;
}

#inspectionDetails .inspectionType.active{
    background-color: #DC0000;
}

#inspectionDetails .inspectionType p{
    margin: 0;
    padding: 0;
}

#inspectionDetails .inspectionType svg{
    margin-right: 5px;
}

#inspectionDetails .createFormGroup{
    margin: 0 10px 10px 0;
}

#inspectionDetails label{
    min-width: 106px;
}

#inspectionDetails select{
    width: 300px;
}

.greyLine{
    width: 100%;
    background: transparent;
    border: 1px solid #EAEEF3;
    margin: 10px 0;
}

.react-datepicker{
    border: none;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    width: 300px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.react-datepicker__month-container{
    height: 100%;
}

.react-datepicker__current-month{
    font-weight: bold;
    font-size: 15px;
    margin-top: 4px;
    color: #454f63;
    text-transform: uppercase;
}

.react-datepicker__day-names{
    margin-top: 10px;
}

.react-datepicker__day-name{
    color: #b9b9b9;
    font-weight: normal;
    font-size: 15px;
}

.react-datepicker__header{
    height: 65px;
}

.react-datepicker__navigation{
    top: 15px;
    outline: none;
}

.react-datepicker__header{
    background: transparent;
    border-radius: unset;
    border: none;
}

.react-datepicker__day{
    border-radius: 50%;
}

.react-datepicker__day:hover{
    border-radius: 50%;
}

.react-datepicker__day--selected{
     background: #dc0000;
}

.react-datepicker__day--selected:hover{
    background: #d30000;
}


.react-datepicker__day--outside-month{
    visibility: hidden;
}

.inspectionSchedule{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 280px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
    padding: 15px;
}

.inspectionSchedule p{
    height: 16px;
    background: transparent;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #78849e;
    margin: 0;
}

.inspectionSchedule p span{
    display: inline-block;
    width: 30px;
    text-align: right;
}

#inspectionDetails .inspectionTypeWrapper label{
    width: 234px;
}

#inspectionDetails .react-datepicker{
    margin: 0 15px 15px 0;
}

.test > div > div {
    position: relative !important;
}