#admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    background-color: #F5F6F7;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.admin-header {
    display: flex;
    padding: 25px 25px 10px 25px;
    width: 100%;
    background-color: #FFFFFF;
}

.admin-header h3 {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #262626;
}

.admin-header p {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #59626a;
}

.admin-header svg {
    margin-right: 10px;
}


#admin .admin-nav {
    display: flex;
    padding: 0 25px 25px 25px;
    justify-content: center;
    background-color: #FFFFFF;
}

#admin .admin-nav button {
    margin: 0 10px;
}

#admin .admin-body {
    margin: 25px;
}

#admin .admin-body .option-button {
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;
    outline: none;
    height: 38px;
    display: flex;
    align-items: center;
}

#admin .admin-body .option-button:disabled i {
    color: #c9c9c9;
}

#admin .admin-body .option-button:disabled:hover i {
    color: #c9c9c9;
}

#admin .admin-body .option-button i {
    color: #454F63;
}

#admin .admin-body .option-button:hover {
    cursor: pointer;
}

#admin .admin-body .option-button:hover i {
    color: #E00000;
}

#admin .admin-body .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    display: block;
}

#admin .admin-body .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
}
#admin .admin-body .autocomplete-dropdown-container:before{
    content: '';
}

#admin .admin-body .autocomplete-dropdown-container .suggestion-item {
    padding: 2px 10px;
}
#admin .admin-body .autocomplete-dropdown-container .suggestion-item--active{
    padding: 2px 10px;
}
