#booking{
    display: flex;
    flex-direction: column;
    width: 820px;
}

#booking h1{
    font-weight: normal;
    font-size: 40px;
    text-align: left;
    color: #262626;
    margin-bottom: 5px;
}

#booking > p{
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #262626;
}

#booking .search-icon{
    width: 36px;
    height: 35px;
    background: #e00000;
    filter: drop-shadow(0px 12px 16px rgba(69, 91, 99, 0.1));
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

#booking .search-button{
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    text-align: left;
    color: #262626;
    user-select: none;
    width: 200px;
}

#booking .search-button:hover{
    cursor: pointer;
}

#booking .search-button:hover .search-icon{
    background-color: #ce0000;
}

#booking .search-bar{
    margin: 15px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
}

#booking .search-bar input{
    height: 48px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
    border:none;
    margin: 7px 7px 0 0;
}

#booking .search-bar button.search{
    height: 48px;
    margin: 7px 0 0 0;
    background: #e00000;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
    border: none;
    width: 22%;
}

#booking .search-bar button.search:hover{
    background: #cc0000;
}

#booking .search-bar input.middle{
    max-width: 49%;
}

#booking .search-bar input.small{
    /*max-width: 25%;*/
    max-width: 32.4%;
}

#booking .react-datepicker-wrapper{
    width: 24%;
}

#booking .react-datepicker-wrapper input,
#booking .react-datepicker-wrapper .react-datepicker__input-container{
    width: 99%;
}

#booking .react-datepicker-wrapper input{
    padding: 6px 12px;
}

#booking .intl-tel-input{
    width: 25%;
    margin: 7px 7px 0 0;
}

#booking .flag-container{
    padding: 0;
}

#booking .selected-flag{
    background-color: #FFFFFF !important;
}





.ais-Hits-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    list-style-type: none;
    padding: 0
}

  .hit-description {
    color: #888;
    font-size: 14px;
    margin-bottom: 0.5em;
  }

  .ais-SearchBox-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: .3rem 1.7rem;
    width: 100%;
    position: relative;
    background-color: #fff;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
}

.ais-SearchBox-submit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    color: 'red'
}
.ais-SearchBox-reset {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    color: 'red'
}

.ais-InstantSearch__root {
    width: 100%
}

button.showFilterButton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    background: #e00000;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
    margin-right: 10px;
}
  