.dotLine{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-right: 7px;
}

.bigDot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #78849e;
}

.littleDot{
    margin-top: 3px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: rgba(120, 132, 158, 0.29);
}